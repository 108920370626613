import React, { useEffect, useState } from "react";
import creditCardStyle from './credit-card.module.css';

const CreditCard = (props) => {
    const [card, setCard] = useState({});
    const [selected, setSelected] = useState(false);
    useEffect(() => {
        setCard(props['card'][0]);
        setSelected(props['card'][0]['selected']);
    }, [props['card']]);


    return (
        <>
            <div className={`${creditCardStyle['credit-card']} ${creditCardStyle[card.brand?.toLowerCase()]} ${card.selected ? creditCardStyle['selectable'] : ''}`}
                onClick={(e) => props.selectCard(card.id, card.token, card.bin)}>
                <input
                    className="form-check-input"
                    type="checkbox"
                    value={card.id}
                    id={`card_${card.id}`}
                    checked={selected}
                    onChange={(event) => {
                        setSelected(true)
                    }} />
                <div>
                    <div className={`${creditCardStyle["credit-card-last4"]} py-4`}>
                        {card.last4?.slice(-4)}
                    </div>
                    <div className={`${creditCardStyle["credit-card-expiry"]}`}>
                        {card.exp_month}/{card.exp_year}
                    </div>
                </div>
            </div>
        </>
    )
}
export default CreditCard;